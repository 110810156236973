import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import bookingLuminor from '../../static/booking/reservation-luminor.pdf';
import bookingUrsulines from '../../static/booking/reservation-ursulines.pdf';
import pdfArchipel from '../../static/booking/Cinelangues-Bon-de-reservation-Archipel.pdf';

import archipel from '../images/cinemas/ARCHIPEL.jpg';
import caumartins from '../images/cinemas/logo-lesCaumartins.jpg';
import parnassiens from '../images/cinemas/logo-lesparnassiens.jpg';
import ursulines from '../images/cinemas/Studio_des_Ursulines.jpg';
import luminor from '../images/cinemas/luminor.png';
import lincoln from '../images/cinemas/LOGO_LINCOLN.png';
import dulac from '../images/cinemas/Dulac-Cinemas.jpeg';
import cineastes from '../images/cinemas/logo-cinema-cineastes.png';

const BookingPage = () => (
  <Layout>
    <SEO
      title="Comment réserver ? Cinélangues"
      description="Cinélangues poursuit, depuis de nombreuses années maintenant, toujours avec autant de passion et de conviction, son action de diffusion des cinématographies."
      pathname="/a-propos-reserver/"
    />
    <div className="page">
      <div className="container jumbotron">
        <div className="row">
          <div className="col-sm-12 col-md-8 header-content ml-2">
            <h1>
              <i className="fal fa-ticket ml-2 mr-2"></i>Comment réserver ?
            </h1>
          </div>
        </div>
      </div>
      <div className="container content reserver">
        <div className="row">
          <div className="card card-orange col-lg-6">
            <div className="card-body">
              <h3 className="mt-0">
                Cinélangues existe depuis de nombreuses années à Paris mais se
                développe peu à peu en région. Les cinémas qui accueillent les
                programmations Cinélangues sont les suivants :
              </h3>
            </div>
          </div>
          <div className="card col-lg-6 card-illustration-1 d-none d-lg-block"></div>
        </div>
        <div className="row">
          <div className="card card-content col-md-6 col-lg-4 text-center">
            <div className="card-body pb-2">
              <div className="row">
                <div className="col-lg-12">
                  <p className="mb-1 text-muted">Académie d’Aix-Marseille</p>
                  <h3 className="mb-0">CINE TOILES</h3>
                  <p className="mb-1 text-muted">
                    Av. François Cuzin, 04000, Digne-les-Bains{' '}
                  </p>
                </div>
              </div>
              <p className="card-text">
                <strong>Valérie Boudoire</strong>
                <br />
                <a href="tel:0142779388">01 42 77 93 88</a>
                <a href="mailto:vboudoire@orange.fr">vboudoire@orange.fr</a>
              </p>
            </div>
          </div>
          <div className="card card-content col-md-6 col-lg-4 text-center">
            <div className="card-body pb-2">
              <div className="row">
                <div className="col-lg-12">
                  <p className="mb-1 text-muted">Académie d’Aix-Marseille</p>
                  <h3 className="mb-0">Cinéma Le Lumière</h3>
                  <p className="mb-1 text-muted">
                    Place Evariste Gras, 13600, La Ciotat
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-content col-md-6 col-lg-4 text-center">
            <div className="card-body pb-2">
              <div className="row">
                <div className="col-lg-12">
                  <p className="mb-1 text-muted">Académie d’Aix-Marseille</p>
                  <h3 className="mb-0">Cinéma Le Lubéron</h3>
                  <p className="mb-1 text-muted">31 Rue Giraud, 84120 Pertuis</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-content col-md-6 col-lg-4 text-center">
            <div className="card-body pb-2">
              <div className="row">
                <div className="col-lg-12">
                  <p className="mb-1 text-muted">Académie de Lille</p>
                  <h3 className="mb-0">Cinéma Etoile Cinémas</h3>
                  <p className="mb-1 text-muted">379 Av. de Lens 62400, Béthune </p>
                </div>
              </div>
              <p className="card-text">
                <strong>Robin CANIFFI</strong>
                <br />
                <a href="mailto:alix@etoile-cinemas.com">alix@etoile-cinemas.com</a>
              </p>
            </div>
          </div>
          <div className="card card-content col-md-6 col-lg-4 text-center">
            <div className="card-body pb-2">
              <div className="row">
                <div className="col-lg-12">
                  <p className="mb-1 text-muted">Académie de Tours</p>
                  <h3 className="mb-0">Studio Cinémas</h3>
                  <p className="mb-1 text-muted">2 Rue des Ursulines 37000 Tours</p>
                </div>
                ,
              </div>
              <p className="card-text">
                <strong>Manon Lory</strong>
                <br />
                <a href="mailto:j.monmarche@studiocine.com">
                  j.monmarche@studiocine.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default BookingPage;
